



































































































































































































import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import User from "@/models/User";
import UserService from "@/services/UserService";
import StringTool from "@/services/tool/StringTool";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Options from "@/models/vue/Options";
import {AuthorityName} from "@/models/Authority";
import LangModule from "@/store/LangModule";
import SnackbarModule from "@/store/SnackbarModule";
import Handler from "@/handlers/Handler";
import {SingleItem} from "@/handlers/interfaces/ContentUI";

@Component
export default class UsersView extends Vue {

	get pages() { return Math.ceil(this.totalItems / this.itemsPerPage) }

	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	dialog: boolean = false
	users: User[] = []
	user: SingleItem<User> = { item: new User() }
	tab: null | undefined = undefined

	roles = [
		{ rol: AuthorityName.ADMIN, value: 0, name: this.lang.administrator },
		{ rol: AuthorityName.MANAGER, value: 1, name: this.lang.manager },
		{ rol: AuthorityName.MARKETING_MANAGER, value: 2, name: this.lang.marketingManager }
	]

	avaliableRolesToCreate = [
		{ rol: AuthorityName.ADMIN, value: 0, name: this.lang.administrator },
		{ rol: AuthorityName.MANAGER, value: 1, name: this.lang.manager },
		{ rol: AuthorityName.MARKETING_MANAGER, value: 2, name: this.lang.marketingManager}
	]

	newUserRole = null
	role: AuthorityName = this.roles[0].rol
	email: string = ""
	password: string = ""
	name: string = ""
	commercialName: string = ""
	page: number = 1
	pageCount: number = 0
	loading: boolean = false
	itemsPerPage: number = 10
	totalItems: number = 0
	options: Options = new Options()
	search: string = ""
	headers = [
		{text: "Id", value: "id", width: "auto", align: "center"},
		//{text: "Rol de usuario", value: "email", width: "auto", align: "center"},
		{text: this.lang.name, value: "name", width: "auto", align: "center"},
		{text: this.lang.email, value: "email", width: "auto", align: "center"}
	]
	emailRules = [
		(v: string) => v && v.length > 0 ? true : this.lang.emailRequired,
		(v: string) => StringTool.validateEmail(v) ? true : this.lang.emailNoValid
	]
	active: boolean = false
	passwordRules = [(v: string) => v && v.length >= 4 ? true : this.lang.password4CharactersRequired]
	titleRules = [(v: string) => v && v.length >= 2 ? true : this.lang.nameMin2Characters]



	created() {
		this.watchOptions()
	}

	rowClick(user: User) {
		this.$router.push({path: "/users/" + user.id})
	}

	openCreateDialog() {
		if (this.form) this.form.resetValidation()
		this.dialog = true
		this.user.item = new User()
		// @ts-ignore
		this.$refs.form.reset()
	}

	createAdminUser() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				this.lang.warning,
				this.lang.userCreateQuestion,
				async () => {
          try {
            await Handler.getItem(this, this.user, () => UserService.postUserAdmin(this.email, this.password, this.name))
            this.dialog = false
          } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo crear el usuario")
            console.log(e)
          }
				}
			))
		}
	}

	createManager() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				this.lang.warning, this.lang.userCreateQuestion,
				async () => {
					await UserService.postManagerUser(this, this.email, this.password, this.name)
					this.dialog = false
				}
			))
		}
	}

	createMarketingManager() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				this.lang.warning,
				this.lang.userCreateQuestion,
				async () => {
					await Handler.getItem(this, { item: new User() }, () =>
						UserService.postMarketingManagerUser(this, this.email, this.password, this.name)
					)
					this.watchOptions()
					this.dialog = false
				}
			))
		}
	}

	createCustomRelationshipManager() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				this.lang.warning,
				this.lang.userCreateQuestion,
				async () => {
					await UserService.postCustomRelationshipManagerUser(this, this.email, this.password, this.name)
					this.dialog = false
				}
			))
		}
	}

	@Watch('page')
	onPageChanged() {
		this.watchOptions()
	}

  @Watch("role")
  onRoleChanged() { this.page = 1 }

  @Watch("options")
  watchOptions() {
    UserService.getUsers(this, this.users, this.page - 1, this.itemsPerPage, this.role, this.search, !this.active)
  }

  @Watch("search")
  watchSearch() {
    if (this.search.length > 2) this.watchOptions()
  }

  @Watch("active")
  watchActive() { this.watchOptions() }

}
